import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';
import IconeHay from "../images/logo-loading.png";
import lodash from "lodash";
import utilService from '../service/UtilService';
import { Link } from 'react-router-dom';

class SelecionarContrato extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async selecionarContrato(contrato) {
        const faturas = await ApiService.buscarFaturasPorContrato({ idcontrato: contrato.contratoid }) || [];
        this.props.onSuccess && this.props.onSuccess({ ...contrato, ...faturas });
        return this.props.navigate("/contratoselecionado");
    }

    formatarNome() {
        return lodash.truncate(lodash.first(this.props.contratos)?.nomeassinante || "Fulano da Silva", { length: 35 });
    }

    formatarCpf() {
        return utilService.formatCpfCnpj(lodash.first(this.props.contratos)?.cpfCnpj || "11122233344");
    }

    render() {
        return (
            <>
                <ul id="progress-bar" className="entry-bookend-progress-container header fundo-contratos">
                    <div className="topo-navegacao">
                        <img alt="Logo da Hay Systems" src={this.props.operacaoTotem.logoPrincipalAutoAtendimento || IconeHay} className={"img-topo-navegacao"} />
                        <p className="nome-usuario-navegacao">{this.formatarNome()}</p>
                        <p className="cpf-usuario-navegacao">{this.formatarCpf()} </p>
                    </div>
                    <ul className="entry-bookend-progress-container header breadcrumb" >
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white" }}> 1 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb">Identificacao</p>
                        <FontAwesomeIcon className='fa-fw icone-resultado chevron-breadcrumb' size="s"  icon={"chevron-right"} />
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white"  }}> 2 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb">Contrato</p>
                        <FontAwesomeIcon className='fa-fw icone-resultado chevron-breadcrumb' size="s"  icon={"chevron-right"} />
                        <li className="progress-item progress-step apagadim">
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                    <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} />
                                    <span class="fa-layers-text fa-1x unselectable" style={{ fontWeight: 900, color: "white"  }}> 3 </span>
                                </span>
                            </span>
                        </li>
                        <p className="texto-breadcrumb apagadim">Faturas</p>
                        
                        <Link to="/identificacao" className="voltar-etapa" >
                            Voltar uma etapa
                        </Link>
                        <p className="texto-breadcrumb">Inicio</p>
                        
                        <li className="progress-item progress-step" >
                            <span className="circle progress-index numero numero-breadcrumb" >
                                <Link to="/menuinicial" >
                                        <span class="fa-layers fa-fw" style={{ width: "100%", height: "100%" }} >
                                            <FontAwesomeIcon className={"fa-fw index unselectable circulo-breadcrumb"} size="3x" icon={"circle"} style={{ color: "lime" }} />
                                            <FontAwesomeIcon className={"fa-fw index unselectable"} size="" icon={"home"} />
                                        </span>
                                </Link>
                            </span>
                        </li>
                    </ul>

                    <span className="entry-bookend-progress-container header" style={{
                        paddingBottom: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        width: "70%",
                        fontSize: "xx-large",
                        fontWeight: 900,
                        position: 'fixed',
                        bottom: 0
                    }}>

                        <p className="selecao-contrato"> 2. Seleção de Contrato: </p>

                        <ul id="progress-bar" className="entry-bookend-progress-container header lista-contratos" style={{height: 450, overflowY: "auto", alignItems:'flex-start'}}>
                            
                            {/* <span className="entry-bookend-progress-container header interior" >
                                <div style={{width: "90%"}}>
                                    <p className="nome-cliente">Heitor Ribeiro Trevisan </p>
                                    <p className="endereco-cliente">Rua Doutor João Antiqueira Elias 07 </p>
                                    <p className="endereco-cliente">Jardim Universitário - Marília</p>
                                    <p className=""></p>
                                    <p className="labels">
                                        <span className="label-contrato">Contrato: 160504</span>
                                        <span className="label-situacao">Atrasada</span>
                                    </p>
                                    
                                </div>
                                <a className="botao-contrato">
                                    <FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"chevron-right"} />
                                </a>

                            </span> */}
                            {
                                this.props.contratos?.filter((contrato) => {
                                    return true
                                }).map(contrato => {
                                    console.log(contrato)
                                    return (
                                        <>
                                            <span className="entry-bookend-progress-container header interior" >
                                                <div style={{width: "90%"}}>
                                                    <p className="nome-cliente">{contrato?.nomeassinante}</p>
                                                    <p className="endereco-cliente">{contrato?.endereco}</p>
                                                    <p className="endereco-cliente">{contrato?.nomecidade}</p>
                                                    <p className=""></p>
                                                    <p className="labels">
                                                        <span className="label-contrato">Contrato: {contrato?.numerocontrato}</span>
                                                        <span className="label-situacao">{contrato?.situacao}</span>
                                                    </p>
                                                    
                                                </div>
                                                <a className="botao-contrato" onClick={() => this.selecionarContrato(contrato)}>
                                                    <FontAwesomeIcon className='fa-fw icone-resultado' size="lg"  icon={"chevron-right"} />
                                                </a>
                                            </span>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </span>
                </ul>
            </>
        )
    }
}
export default withRouter(SelecionarContrato);