import React, { Component } from 'react';
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }    
    getAnimate() {
        var classes = "container animate__animated ";
        if (this.props.visible){
            classes += (this.props.entrada ? this.props.entrada : "animate__zoomInDown") + " ";
        } else {
            classes += (this.props.saida ? this.props.saida : "animate__zoomOutDown") + " ";
        }
        return classes;
    }
    render() {
        return (
            <>
                <div className={"backdrop " + (this.props.visible ? "visible" : 'invisible') } >
                    <div className={this.getAnimate()} >
                        <ul id="progress-bar" className="entry-bookend-progress-container header">
                            {this.props.children}
                            <p className="footer-modal">
                                <li className="progress-item progress-step resultado" >
                                    <span className="circle progress-index larguinho" style={{margin: 1, cursor: "pointer"}} onClick={()=> this.props.onClose()}>
                                        <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"times"}  /> Fechar
                                    </span>
                                </li>
                            </p>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}
export default Modal;
