



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ApiService from '../service/ApiService';
import { withRouter } from '../ganchos/withRouter';
import { toast } from 'react-toastify';

class LiberacaoConfianca extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async efetuarLiberacaoEmConfianca() {
        const retorno = await ApiService.liberacaoEmConfianca({
            idcontrato: this.props.contrato.contratoid,
        });
        switch (retorno?.state) {
            case "menu.mensagem.liberacao.em.confianca.realizada":
                toast.success("Documento Processado com sucesso");
                this.props.navigate("/contratoselecionado");
                return this.props.onSuccess();
            case "menu.mensagem.liberacao.em.confianca.nao.realizada":
            default:
                toast.error("Ocorreu um erro na Liberação em Confiança. Contate a nossa central de atendimento.",{
                    autoClose: false,
                    position: "bottom-center",
                    theme: "colored",
                    closeOnClick: true,
                });
        }
    }
    render() {
        return (
            <>
                <div className="controller-header">
                    <p className="header"><FontAwesomeIcon className={"fa-fw index unselectable"} icon={"arrow-right-from-bracket"} /> Liberação em Confiança: </p>
                </div>
                <ul id="progress-bar" className="entry-bookend-progress-container header">
                    
                        <span className="circle progress-index larguinho">
                            <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" title={this.props.contrato?.situacao} icon={"user"} />
                            <span className="index elipsis"> Assinante: {this.props.contrato?.nomeassinante} </span>
                            <p className='linha-contrato'>Endereço: {this.props.contrato?.endereco}</p>
                            <p className='linha-contrato'>Situacao: {this.props.contrato?.situacao} IdAssinante: [{this.props.contrato?.idassinante}] Contrato: [{this.props.contrato?.numerocontrato}] </p>
                            <p>Você pode solicitar a liberação em confiança por mais três dias, até que você regularize a sua situação financeira conosco.</p>
                            <p>Caso o pagamento não seja confirmado em 3 dias úteis, o sinal será
                            reduzido.</p>                            
                            <li className="progress-item progress-step" title={"Solicitar Liberação em Confiança"} style={{ ...{margin: 1, cursor: "pointer"},}}  onClick={() => this.efetuarLiberacaoEmConfianca(this.props.contrato)} >
                                <span className="circle progress-index" >
                                    <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"fa-handshake-angle"}  /> Solicitar Liberação em Confiança
                                </span>
                            </li>
                        </span>
                    <li className='break'></li>
                </ul>
            </>
        )
    }
}
export default withRouter(LiberacaoConfianca);






