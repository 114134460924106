import React, { Component } from 'react';
import "./style.css";
import IconeHay from "../../../images/logo-loading.png";
import UtilService from '../../../service/UtilService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mensagem: 'Aguarde alguns instantes...',
            mensagemPadrao: 'Aguarde alguns instantes...',
        }
    }

    componentDidMount(){
        window.addEventListener("showLoading", (event) => {
            console.log(event.detail);
            this.setState({ show: true, mensagem: event?.detail?.mensagem || this.state.mensagem, cbCancelamento: event?.detail?.cbCancelamento });
        });

        window.addEventListener("hideLoading", (event) => {
            this.setState({ show: false, mensagem: this.state.mensagemPadrao });
        });
    }

    async acaoCancelar(){
        const retorno = await this.state.cbCancelamento();
    }

    render() {
        return (
            <>
                <div className="backdrop-loader" style={UtilService.reactShow(this.state.show)}>
                    <div className={"container-loader"} >
                        <svg viewBox="0 0 100 100">
                            <defs>
                                <filter id="shadow-loader">
                                    <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor={"#0080FF"} />
                                </filter>
                            </defs>
                            <circle className={"circle-loader"} cx="50" cy="50" r="45" />
                        </svg>
                        <img alt="Logo da Hay Systems" src={this.props.operacaoTotem.logoLoadingAutoAtendimento || IconeHay} className={"img-loader"} />
                    </div>
                    <div className="controller-header container-loader-mensagem" style={{ flexDirection: "column" }}>
                        <p className="header" style={{width: '100%', textAlign: 'center', marginTop: 20, color: "white",boxShadow: "none", border: 0}}>
                            <FontAwesomeIcon className={"fa-fw index unselectable"} icon={this.props.icon} /> {this.state.mensagem}
                        </p>
                        <ul id="progress-bar" className="entry-bookend-progress-container header" style={ UtilService.reactShow(this.state.cbCancelamento) }>
                            <li className="progress-item progress-step" title={"Cancelar"} style={{ ...{margin: 1, cursor: "pointer"},}}  onClick={()=> this.acaoCancelar() }>
                                <span className="circle progress-index" style={{color: "white"}} >
                                    <FontAwesomeIcon className='fa-fw icone-resultado' size="lg" icon={"times"}  /> Cancelar
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}
export default Loader;
