const utilService = {
    getUrlParams: (field) => {
        return new URLSearchParams(window.location.search).get(field);
    },
    reactShow: (teste) => {
        if (!teste) {
            return { display: 'none' };
        }
        return { display: 'flex' };
    },
    reactEnabled: (teste) =>{
        if (!teste) {
            return { opacity: 0.7, pointerEvents: 'none'};
        }
        return { opacity: 1  };
    },
    showLoading: (mensagem, cbCancelamento) => {
        var event = new CustomEvent("showLoading", { detail: { mensagem , cbCancelamento } });
        window.dispatchEvent(event);
    },
    hideLoading: () => {
        var event = new CustomEvent("hideLoading", {  });
        window.dispatchEvent(event);
    },
    setState: async (newState, contexto) => {
        try {
            return new Promise(resolve => contexto.setState(newState, resolve));
        } catch (err) {
            console.log(err);
        }
    },
    aguardar: async (tempo) => {
        try {
            return new Promise((resolve) => setTimeout(resolve, tempo));
        } catch (err) {
            console.log(err);
        }
    },
    aguardarCb:  (tempo, cb) => {
        return setTimeout(cb, tempo);
    },
    iniciarTimerSessao: async (aoTerminar, toast) => {
        var tempo = 120000;
        if (utilService.timeoutSessaoId){
            clearTimeout(utilService.timeoutSessaoId);
        }
        const tempoFormatado = tempo / 1000;
        toast.warn('Para sua segurança, sua sessão será terminada em '+ tempoFormatado +' segundos...', {
            toastId: "identificador-sessao",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: null,
            theme: "colored",
            onClose: aoTerminar,
            progress: 1,
            position: "bottom-right",
        });

        return await utilService.contagemRegressiva(tempo, (tempoAtualizado) => {
            const tempoFormatado = tempoAtualizado / 1000;
            const mesg = 'Para sua segurança, sua sessão será terminada em '+ tempoFormatado +' segundos...';
            const percentual = tempoAtualizado / tempo;
            toast.update("identificador-sessao", {  
                render: mesg,
                progress: percentual
            });
        }, ()=> {
            toast.dismiss("identificador-sessao");
        });
    },
    contagemRegressiva: async (tempo, fnUpdate, fnFinal) => {
        if (tempo > 0){            
            utilService.timeoutSessaoId = utilService.aguardarCb(1000, async () => {
                tempo -= 1000;
                fnUpdate(tempo);
                return await utilService.contagemRegressiva(tempo, fnUpdate, fnFinal);
            });
        } else {
            return fnFinal();
        }
    },
    formatCpfCnpj: (input) => {   
        if (String(input).length > 11){
            var str = input + '';
            str = str.replace(/\D/g, '');
            str = str.replace(/^(\d{2})(\d)/, '$1.$2');
            str = str.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
            str = str.replace(/\.(\d{3})(\d)/, '.$1/$2');
            str = str.replace(/(\d{4})(\d)/, '$1-$2');
            return str;        
        }
        else{
            var str = input + '';
            str = str.replace(/\D/g, '');
            str = str.replace(/(\d{3})(\d)/, '$1.$2');
            str = str.replace(/(\d{3})(\d)/, '$1.$2');
            str = str.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            return str;
        }    
    }
}

export default utilService;